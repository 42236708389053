<template>
  <div id="center-management" class="d-flex flex-column">
    <v-row no-gutters>
      <v-col md="12">
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
          class="my-10"
        ></v-progress-linear>
        <div class="my-3">
          <h3 v-if="filtered.length != 1">Total: {{ filtered.length }} QCs</h3>
          <h3 v-else>Total: {{ filtered.length }} qc</h3>
        </div>
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items="filtered"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          hide-default-footer
          id="qcSummary"
          class="elevation-0 mb-5"
          fixed-header
          height="70vh"
        >
          <template v-slot:header="{ props }">
            <tr class="fixed-row-filter">
              <th
                v-for="header in props.headers"
                :key="header.text"
                :style="'width:' + header.width + '; min-width:' + header.width + ';'"
              ></th>
            </tr>
            <tr class="fixed-row-filter">
              <th v-for="header in props.headers" :key="header.text">
                <div v-if="filters.hasOwnProperty(header.value)">
                  <Autocomplete
                    v-if="filters.hasOwnProperty(header.value)"
                    @setFilter="setFilter"
                    :styleBorder="'border-bottom'"
                    :keyFilter="header.value"
                    :selectedInit="filters[header.value]"
                    :listItem="groupColumnValueList(header.value)"
                  />
                </div>
              </th>
            </tr>
          </template>
          <template v-slot:[`item.no`]="{ index }">
            <td>
              <p>{{ index + 1 }}</p>
            </td>
          </template>
          <template v-slot:[`item.teacherCode`]="{ item }">
            <td>
              <p>{{ item.teacherCode ? item.teacherCode : '-' }}</p>
            </td>
          </template>
          <template v-slot:[`item.fullname`]="{ item }">
            <td>
              <p>{{ item.fullname ? item.fullname : '-' }}</p>
            </td>
          </template>
          <template v-slot:[`item.nbrStu`]="{ item }">
            <td>
              <p>{{ item.nbrStu ? item.nbrStu : '-' }}</p>
            </td>
          </template>
          <template v-slot:[`item.nbrStuDiscount100`]="{ item }">
            <td>
              <p>{{ item.nbrStuDiscount100  }}</p>
            </td>
          </template>
          <template v-slot:[`item.nbrStopped`]="{ item }">
            <td>
              <p>{{ item.nbrStopped  }}</p>
            </td>
          </template>
          <!-- <template v-slot:[`item.salaryPerHours`]="{ item }">
            <td>
              <p>{{ item.salaryPerHours }}</p>
            </td>
          </template> -->
          <template v-slot:[`item.amount`]="{ item }">
            <td>
              <p>{{ item.amount }}</p>
            </td>
          </template>
          <template v-slot:[`item.stuStartAt23`]="{ item }">
            <td>
              <p>{{ item.stuStartAt23 }}</p>
            </td>
          </template>
          <template v-slot:[`item.stuNumToIncome`]="{ item }">
            <td>
              <p>{{ item.stuNumToIncome }}</p>
            </td>
          </template>
          <template v-slot:[`item.stuNumHaveTeacherNote`]="{ item }">
            <td>
              <p>{{ item.stuNumHaveTeacherNote }}</p>
            </td>
          </template>
          <template v-slot:[`item.stuNumHaveNoTeacherNote`]="{ item }">
            <td>
              <p>{{ item.stuNumHaveNoTeacherNote }}</p>
            </td>
          </template>
          <template v-slot:[`item.stuNumHaveGuardNote`]="{ item }">
            <td>
              <p>{{ item.stuNumHaveGuardNote }}</p>
            </td>
          </template>
          <template v-slot:[`item.stuNumHaveNoGuardNote`]="{ item }">
            <td>
              <p>{{ item.stuNumHaveNoGuardNote }}</p>
            </td>
          </template>
          <template v-slot:[`item.achievementRate`]="{ item }">
            <td>
              <p>{{ item.achievementRate }}</p>
            </td>
          </template>
          <template v-slot:[`item.salary`]="{ item }">
            <td>
              <p>{{ item.salary ? 'Y' : 'N' }}</p>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between px-2">
      <v-select
        outlined
        hide-details
        dense
        :value="itemsPerPage"
        :items="[20, 50, 100, 200]"
        @change="itemsPerPage = parseInt($event, 10)"
        class="select-paging"
      ></v-select>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        elevation="0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Autocomplete from '@/components/Utils/Autocomplete.vue'

export default {
  props: {
    fromMonth: String,
    locationID: Number,
    isLoading: Boolean,
    listItem: Array,
  },
  components: { Autocomplete },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 100,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          width: '50px',
        },
        {
          text: 'QC Code',
          value: 'teacherCode',
          sortable: false,
          width: '80px',
        },
        {
          text: 'QC Name',
          value: 'fullname',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Numb of Active Students',
          value: 'nbrStu',
          sortable: false,
          width: '50px',
        },
        {
          text: 'No.Stu.Disc.100%',
          value: 'nbrStuDiscount100',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Nbr Stopped',
          value: 'nbrStopped',
          sortable: false,
          width: '50px',
        },
        // {
        //   text: 'Rate',
        //   value: 'salaryPerHours',
        //   sortable: false,
        //   width: '50px',
        // },
        {
          text: 'Amount',
          value: 'amount',
          sortable: false,
          width: '50px',
        },
        {
          text: 'HS bắt đầu từ 23 hàng tháng',
          value: 'stuStartAt23',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Số HS tính lương CSKH',
          value: 'stuNumToIncome',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Số HS có NX từ TA',
          value: 'stuNumHaveTeacherNote',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Số HS không có NX từ TA',
          value: 'stuNumHaveNoTeacherNote',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Số HS có NX từ PH',
          value: 'stuNumHaveGuardNote',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Số HS không có NX từ PH',
          value: 'stuNumHaveNoGuardNote',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Achivement Rate (%)',
          value: 'achievementRate',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Salary',
          value: 'salary',
          sortable: false,
          width: '50px',
        },
      ],
      filters: {
        teacherCode: [],
        fullname: [],
        nbrStu: [],
        nbrStuDiscount100: [],
        nbrStopped: [],
        // salaryPerHours: [],
        amount: [],
        stuStartAt23: [],
        stuNumToIncome: [],
        stuNumHaveTeacherNote: [],
        stuNumHaveNoTeacherNote: [],
        stuNumHaveGuardNote: [],
        stuNumHaveNoGuardNote: [],
        achievementRate: [],
        salary: [],
      },
    }
  },
  created() {},
  computed: {
    filtered() {
      return this.listItem.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'comment'
              ? this.filters[f].reduce((value, el) => {
                  if (d.comment?.length > 0 && el === 'Commented') {
                    value = true
                  }
                  if ((d.comment === '' || d.comment === null) && el === 'No comment') {
                    value = true
                  }
                  return value
                }, false)
              : this.filters[f].includes(d[f]))
          )
        })
      })
    },
  },
  watch: {
    fromDate: function () {},
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('stopFilter', JSON.stringify(this.filters))
    },
    groupColumnValueList(val) {
      return val === 'comment'
        ? ['Commented', 'No comment']
        : val === 'programme'
        ? this.listItem.map(d => d[val]).filter(y => y !== 'Short Course' && y !== 'Outing lesson')
        : this.listItem.map(d => d[val]).filter(y => y)
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.select-paging {
  height: 32px;
  max-width: 100px;
}
#qcSummary {
  tbody {
    tr {
      td {
        text-align: center;
      }

      &:nth-of-type(even) {
        background-color: #eeeeee !important;

        td {
          background-color: #f3faff !important;
        }

        &:hover {
          td {
            background-color: #dbecf8 !important;
          }
        }
      }

      &:nth-of-type(odd) {
        background-color: #d7dbdd !important;
      }
    }
  }
}
</style>
