<template>
  <div id="center-management" class="d-flex flex-column">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <div class="d-flex">
            <h2 class="pt-1">QC Customer Care</h2>
          </div>
          <div class="d-flex justify-space-between mt-3">
            <div class="d-flex">
              <v-autocomplete
                v-model="locationID"
                item-text="location"
                append-icon="keyboard_arrow_down"
                item-value="locationID"
                :items="centers"
                label="Center"
                outlined
                @change="changeFilter"
                class="mr-3"
                dense
              ></v-autocomplete>
              <div :style="{ zIndex: 4 }" :class="'datepicker'" class="datapicker-with-label mr-2">
                <p>Month</p>

                <date-picker
                  v-model="fromMonth"
                  :format="'MM/YYYY'"
                  type="month"
                  @change="changeFilter"
                  placeholder="Select month"
                ></date-picker>
              </div>
              <!-- <v-date-picker @change="changeFilter" v-model="" type="month"></v-date-picker> -->
              <v-btn color="primary" @click="searchReport">Search</v-btn>
            </div>
          </div>
          <div>
            <v-tabs v-model="tab" class="tab-sadown mb-2">
              <v-tab> <h3 class="text-normal">Summary</h3></v-tab>
              <v-tab> <h3 class="text-normal">Detail</h3> </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <div>
                  <QCSummary
                    :fromMonth="fromMonth"
                    :locationID="locationID"
                    :isLoading="isLoading"
                    :listItem="QCSummary"
                  />
                </div>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <QCDetail
                    :fromMonth="fromMonth"
                    :isLoading="isLoading"
                    :locationID="locationID"
                    :listItem="QCDetail"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import { monthly_comment } from '@/api/monthly.js'
import QCDetail from './QCDetail.vue'
import QCSummary from './QCSummary.vue'

import 'vue2-datepicker/index.css'

export default {
  components: { 'date-picker': DatePicker, QCDetail, QCSummary },
  data() {
    return {
      isLoading: false,
      QCSummary: [],
      QCDetail: [],
      tab: 0,
      centers: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      locationID: JSON.parse(localStorage.getItem('currentCenter')),
      fromMonth: new Date(dayjs().subtract(1, 'month').format()),
    }
  },
  created() {
    if (localStorage.getItem('qcCareCenter'))
      this.locationID = parseInt(localStorage.getItem('qcCareCenter'))
    if (localStorage.getItem('qcCareMonth'))
      this.fromMonth = new Date(localStorage.getItem('qcCareMonth'))
    this.getDataSummary()
    this.getDataDetail()
  },
  computed: {},
  watch: {
    fromMonth: function () {},
  },
  methods: {
    changeFilter() {
      localStorage.setItem('qcCareCenter', this.locationID)
      localStorage.setItem('qcCareMonth', this.fromMonth)
      this.init()
    },
    searchReport() {
      this.getDataSummary()
      this.getDataDetail()
    },
    async getDataSummary() {
      this.isLoading = true
      await monthly_comment
        .getSummaryReport(this.locationID, dayjs(this.fromMonth).format('YYYY-MM'))
        .then(res => {
          this.QCSummary = res
          this.isLoading = false
        })
    },
    async getDataDetail() {
      this.isLoading = true
      await monthly_comment
        .getDetailReport(this.locationID, dayjs(this.fromMonth).format('YYYY-MM'))
        .then(res => {
          this.QCDetail = res
          this.isLoading = false
        })
    },

    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.datapicker-with-label {
  position: relative;
  p {
    position: absolute;
    top: -7px;
    z-index: 100;
    left: 10px;
    font-size: 10px;
    background: #fff;
  }
}
</style>
