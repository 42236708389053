var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column",attrs:{"id":"center-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"my-3"},[(_vm.filtered.length != 1)?_c('h3',[_vm._v("Total: "+_vm._s(_vm.filtered.length)+" QCs")]):_c('h3',[_vm._v("Total: "+_vm._s(_vm.filtered.length)+" qc")])]),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0 mb-5",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","id":"qcSummary","fixed-header":"","height":"70vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text,style:('width:' + header.width + '; min-width:' + header.width + ';')})}),0),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('td',[_c('p',[_vm._v(_vm._s(index + 1))])])]}},{key:"item.teacherCode",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.teacherCode ? item.teacherCode : '-'))])])]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.fullname ? item.fullname : '-'))])])]}},{key:"item.nbrStu",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.nbrStu ? item.nbrStu : '-'))])])]}},{key:"item.nbrStuDiscount100",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.nbrStuDiscount100))])])]}},{key:"item.nbrStopped",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.nbrStopped))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.amount))])])]}},{key:"item.stuStartAt23",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.stuStartAt23))])])]}},{key:"item.stuNumToIncome",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.stuNumToIncome))])])]}},{key:"item.stuNumHaveTeacherNote",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.stuNumHaveTeacherNote))])])]}},{key:"item.stuNumHaveNoTeacherNote",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.stuNumHaveNoTeacherNote))])])]}},{key:"item.stuNumHaveGuardNote",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.stuNumHaveGuardNote))])])]}},{key:"item.stuNumHaveNoGuardNote",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.stuNumHaveNoGuardNote))])])]}},{key:"item.achievementRate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.achievementRate))])])]}},{key:"item.salary",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.salary ? 'Y' : 'N'))])])]}}],null,true)}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex justify-space-between px-2"},[_c('v-select',{staticClass:"select-paging",attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}}),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }