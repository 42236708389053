var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"my-3"},[(_vm.filtered.length != 1)?_c('h3',[_vm._v("Total: "+_vm._s(_vm.filtered.length)+" students")]):_c('h3',[_vm._v("Total: "+_vm._s(_vm.filtered.length)+" student")])]),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0 mb-5",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","id":"qcDetail","fixed-header":"","height":"70vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text,style:('width:' + header.width + '; min-width:' + header.width + ';')})}),0),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('td',[_c('p',[_vm._v(_vm._s(index + 20 * (_vm.page - 1) + 1))])])]}},{key:"item.noTAComment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.noTAComment))])])]}},{key:"item.noTANotComment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.noTANotComment))])])]}},{key:"item.noGuardianComment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.noGuardianComment))])])]}},{key:"item.noGuardianNotComment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.noGuardianNotComment))])])]}},{key:"item.noStuStartAfter23OfMonth",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.noStuStartAfter23OfMonth))])])]}},{key:"item.teacherCode",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.teacherCode))])])]}},{key:"item.teacherName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.teacherName))])])]}},{key:"item.evaluate",fn:function(ref){
var item = ref.item;
return [(item.evaluate)?_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(item.evaluate)}})]):_c('td',[_c('p',[_vm._v("-")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(item.status)}})]):_c('td',[_c('p',[_vm._v("-")])])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment)?_c('td',[_c('p',{staticStyle:{"text-align":"start"},domProps:{"innerHTML":_vm._s(item.comment)}})]):_c('td',[_c('p',[_vm._v("-")])])]}},{key:"item.actualStartDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(" "+_vm._s(item.actualStartDate ? _vm._dayjs(item.actualStartDate).format('DD/MM/YYYY') : '-')+" ")])])]}},{key:"item.actualEndDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(" "+_vm._s(item.actualEndDate ? _vm._dayjs(item.actualEndDate).format('DD/MM/YYYY') : '-')+" ")])])]}},{key:"item.guardianTime",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.guardianTime ? _vm._dayjs(item.guardianTime).format('DD/MM/YYYY') : '-'))])])]}},{key:"item.remark",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.remark ? item.remark : '-'))])])]}},{key:"item.studentCode",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',{staticClass:"link",on:{"click":function($event){return _vm.viewProfile(item.userID)}}},[_vm._v(" "+_vm._s(item.studentCode ? item.studentCode : '-')+" ")])])]}},{key:"item.studentName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',{staticClass:"link",on:{"click":function($event){return _vm.viewProfile(item.userID)}}},[_vm._v(" "+_vm._s(item.studentName ? item.studentName : '-')+" ")])])]}},{key:"item.classCode",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',{staticClass:"link",on:{"click":function($event){return _vm.viewClassDetail(item.classID)}}},[_vm._v(" "+_vm._s(item.classCode ? item.classCode : '-')+" ")])])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between mt-2 px-2"},[_c('v-select',{staticClass:"select-paging",attrs:{"width":"200","outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}}),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }