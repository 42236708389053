<template>
  <div class="d-flex flex-column">
    <v-row no-gutters>
      <v-col md="12">
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
          class="my-10"
        ></v-progress-linear>
        <div class="my-3">
          <h3 v-if="filtered.length != 1">Total: {{ filtered.length }} students</h3>
          <h3 v-else>Total: {{ filtered.length }} student</h3>
        </div>
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items="filtered"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          hide-default-footer
          id="qcDetail"
          class="elevation-0 mb-5"
          fixed-header
          height="70vh"
        >
          <template v-slot:header="{ props }">
            <tr class="fixed-row-filter">
              <th
                v-for="header in props.headers"
                :key="header.text"
                :style="'width:' + header.width + '; min-width:' + header.width + ';'"
              ></th>
            </tr>
            <tr class="fixed-row-filter">
              <th v-for="header in props.headers" :key="header.text">
                <div v-if="filters.hasOwnProperty(header.value)">
                  <Autocomplete
                    v-if="filters.hasOwnProperty(header.value)"
                    @setFilter="setFilter"
                    :styleBorder="'border-bottom'"
                    :keyFilter="header.value"
                    :selectedInit="filters[header.value]"
                    :listItem="groupColumnValueList(header.value)"
                  />
                </div>
              </th>
            </tr>
          </template>
          <template v-slot:[`item.no`]="{ index }">
            <td>
              <p>{{ index + 20 * (page - 1) + 1 }}</p>
            </td>
          </template>
          <template v-slot:[`item.noTAComment`]="{ item }">
            <td>
              <p>{{ item.noTAComment }}</p>
            </td>
          </template>
          <template v-slot:[`item.noTANotComment`]="{ item }">
            <td>
              <p>{{ item.noTANotComment }}</p>
            </td>
          </template>
          <template v-slot:[`item.noGuardianComment`]="{ item }">
            <td>
              <p>{{ item.noGuardianComment }}</p>
            </td>
          </template>
          <template v-slot:[`item.noGuardianNotComment`]="{ item }">
            <td>
              <p>{{ item.noGuardianNotComment }}</p>
            </td>
          </template>
          <template v-slot:[`item.noStuStartAfter23OfMonth`]="{ item }">
            <td>
              <p>{{ item.noStuStartAfter23OfMonth }}</p>
            </td>
          </template>
          <template v-slot:[`item.teacherCode`]="{ item }">
            <td>
              <p>{{ item.teacherCode }}</p>
            </td>
          </template>
          <template v-slot:[`item.teacherName`]="{ item }">
            <td>
              <p>{{ item.teacherName }}</p>
            </td>
          </template>
          <template v-slot:[`item.evaluate`]="{ item }">
            <td v-if="item.evaluate">
              <p v-html="item.evaluate"></p>
            </td>
            <td v-else>
              <p>-</p>
            </td>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td v-if="item.status">
              <p v-html="item.status"></p>
            </td>
            <td v-else>
              <p>-</p>
            </td>
          </template>
          <template v-slot:[`item.comment`]="{ item }">
            <td v-if="item.comment">
              <p style="text-align: start;" v-html="item.comment"></p>
            </td>
            <td v-else>
              <p>-</p>
            </td>
          </template>
          <template v-slot:[`item.actualStartDate`]="{ item }">
            <td>
              <p>
                {{ item.actualStartDate ? _dayjs(item.actualStartDate).format('DD/MM/YYYY') : '-' }}
              </p>
            </td>
          </template>
          <template v-slot:[`item.actualEndDate`]="{ item }">
            <td>
              <p>
                {{ item.actualEndDate ? _dayjs(item.actualEndDate).format('DD/MM/YYYY') : '-' }}
              </p>
            </td>
          </template>
          <template v-slot:[`item.guardianTime`]="{ item }">
            <td>
              <p>{{ item.guardianTime ? _dayjs(item.guardianTime).format('DD/MM/YYYY') : '-' }}</p>
            </td>
          </template>
          <template v-slot:[`item.remark`]="{ item }">
            <td>
              <p>{{ item.remark ? item.remark : '-' }}</p>
            </td>
          </template>
          <template v-slot:[`item.studentCode`]="{ item }">
            <td>
              <p class="link" @click="viewProfile(item.userID)">
                {{ item.studentCode ? item.studentCode : '-' }}
              </p>
            </td>
          </template>
          <template v-slot:[`item.studentName`]="{ item }">
            <td>
              <p class="link" @click="viewProfile(item.userID)">
                {{ item.studentName ? item.studentName : '-' }}
              </p>
            </td>
          </template>
          <template v-slot:[`item.classCode`]="{ item }">
            <td>
              <p class="link" @click="viewClassDetail(item.classID)">
                {{ item.classCode ? item.classCode : '-' }}
              </p>
            </td>
          </template>
        </v-data-table>
        <div class="d-flex justify-space-between mt-2 px-2">
          <v-select
            width="200"
            outlined
            hide-details
            dense
            :value="itemsPerPage"
            :items="[20, 50, 100, 200]"
            @change="itemsPerPage = parseInt($event, 10)"
            class="select-paging"
          ></v-select>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            elevation="0"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import Autocomplete from '@/components/Utils/Autocomplete.vue'

import 'vue2-datepicker/index.css'

export default {
  props: {
    fromMonth: String,
    locationID: Number,
    isLoading: Boolean,
    listItem: Array,
  },
  components: { Autocomplete },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 100,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Student Code',
          value: 'studentCode',
          sortable: false,
          width: '120px',
        },
        {
          text: 'Student Name',
          value: 'studentName',
          sortable: false,
          width: '180px',
        },
        {
          text: 'Số HS có NX từ TA',
          value: 'noTAComment',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Số HS không có NX từ TA',
          value: 'noTANotComment',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Số HS có NX từ PH',
          value: 'noGuardianComment',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Số HS không có NX từ PH',
          value: 'noGuardianNotComment',
          sortable: false,
          width: '100px',
        },
        {
          text: 'HS bắt đầu từ 23 hàng tháng',
          value: 'noStuStartAfter23OfMonth',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Remark',
          value: 'remark',
          sortable: false,
          width: '100px',
        },

        {
          text: 'Actual Start Date',
          value: 'actualStartDate',
          sortable: false,
          width: '140px',
        },
        {
          text: 'Actual End Date',
          value: 'actualEndDate',
          sortable: false,
          width: '140px',
        },
        {
          text: 'Class Code',
          value: 'classCode',
          sortable: false,
          width: '160px',
        },
        {
          text: 'Teacher Code',
          value: 'teacherCode',
          sortable: false,
          width: '120px',
        },
        {
          text: 'Teacher Name',
          value: 'teacherName',
          sortable: false,
          width: '180px',
        },
        {
          text: "Teacher's Comment",
          value: 'comment',
          sortable: false,
          width: '400px',
        },
        {
          text: 'Evaluate',
          value: 'evaluate',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Guardian Time',
          value: 'guardianTime',
          sortable: false,
          width: '120px',
        },
      ],
      filters: {
        studentCode: [],
        studentName: [],
        noTAComment: [],
        noTANotComment: [],
        noGuardianComment: [],
        noGuardianNotComment: [],
        noStuStartAfter23OfMonth: [],
        remark: [],
        actualStartDate: [],
        actualEndDate: [],
        classCode: [],
        teacherCode: [],
        teacherName: [],
        comment: [],
        evaluate: [],
        status: [],
        guardianTime: [],
      },
    }
  },
  created() {},
  computed: {
    filtered() {
      return this.listItem.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'comment'
              ? this.filters[f].reduce((value, el) => {
                  if (d.comment?.length > 0 && el === 'Commented') {
                    value = true
                  }
                  if ((d.comment === '' || d.comment === null) && el === 'No comment') {
                    value = true
                  }
                  return value
                }, false)
              : this.filters[f].includes(d[f]))
          )
        })
      })
    },
  },
  watch: {
    fromDate: function () {},
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('stopFilter', JSON.stringify(this.filters))
    },
    groupColumnValueList(val) {
      return val === 'comment'
        ? ['Commented', 'No comment']
        : val === 'programme'
        ? this.listItem.map(d => d[val]).filter(y => y !== 'Short Course' && y !== 'Outing lesson')
        : this.listItem.map(d => d[val]).filter(y => y)
    },
    viewProfile(userId) {
      this.$router.push({
        name: 'account-management-view-account',
        params: { userId: userId },
      })
    },
    viewClassDetail(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: {
          classID: classID,
        },
      })
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.select-paging {
  height: 32px;
  max-width: 100px;
}

.link {
  cursor: pointer;
  color: #427df2 !important;
  &:hover {
    text-decoration: underline;
    font-weight: 700;
  }
}
#qcDetail {
  tbody {
    tr {
      td{
        text-align: center;
      }

      &:nth-of-type(even) {
        background-color: #eeeeee !important;

        td {
          background-color: #f3faff !important;
        }

        &:hover {
          
          td {
            background-color: #dbecf8 !important;
          }
        }
      }

      &:nth-of-type(odd) {
        background-color: #d7dbdd !important;
      }
    }
  }
}

// #qcDetail tbody tr:nth-of-type(even):hover td {
//   background-color: #dbecf8 !important;
// }
// #qcDetail tbody tr:nth-of-type(odd):hover td {
//   background-color: #d7dbdd !important;
// }
// #qcDetail tbody tr:nth-of-type(even) td {
//   background-color: #f3faff !important;
// }
// #qcDetail tbody tr:nth-of-type(even) {
//   background-color: #eeeeee !important;
// }
</style>
